import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="お問い合わせ" />
    <div className="intro">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="mb-2">お問い合わせ</h1>
            <p>メールにてお問い合わせください</p>
            <p><a href="mailto:j-mind@j-mind.co.jp"><strong>j-mind@j-mind.co.jp</strong></a></p>
          </div>
         
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 mb-4">
          <Call button={false} />
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
